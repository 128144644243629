<template>
	<div>
		<div class="sidebar">
			<div class="logo">
				<img class="small" src="../../assets/teleradio-sm.png" alt="">
				<img class="full" src="../../assets/teleradio.png" alt="">
			</div>
			<div class="snav-container">
				<div class="snav">
					<div class="title">Menu</div>
					<ul>
						<li>
							<router-link to="/radiologist/dashboard">
								<i class="fa fa-dashboard"></i>
								<span>Dashboard</span>
							</router-link>
						</li>
						<li>
							<router-link to="/radiologist/reports/pending">
								<i class="fa fa-plus-square"></i>
								<span class="text">Reports</span>
							</router-link>
						</li>
						<li>
							<router-link to="/radiologist/templates">
								<i class="fa fa-file-word-o"></i>
								<span class="text">Templates</span>
							</router-link>
						</li>

						<li :class="{ open: openDropdown == 'accounts' }">
							<a @click="openDropdown = openDropdown == 'accounts' ? '' : 'accounts'">
								<i class="pull-right fa fa-chevron-left"></i>
								<i class="fa fa-briefcase"></i>
								<span>Accounts</span>
							</a>
							<ul>
								<li>
									<router-link to="/radiologist/my-received">
										<i class="fa fa-plus"></i>
										<span class="text">My Received</span>
									</router-link>
								</li>
								<li>
									<router-link to="/radiologist/billing">
										<i class="fa fa-credit-card"></i>
										<span class="text">My Billing</span>
									</router-link>
								</li>
							</ul>
						</li>
						<li>
							<router-link @click.prevent="logOut" to="/">
								<i class="fa fa-lock"></i>
								<span class="text">Logout</span>
							</router-link>
						</li>
						<!-- <li :class="{open: openDropdown == 'report'}">
							<a @click="openDropdown = openDropdown == 'report' ? '' : 'report'">
								<i class="pull-right fa fa-chevron-left"></i>
								<i class="fa fa-briefcase"></i>
								<span>Report</span>
							</a>
							<ul>
								<li>
									<router-link to="/center/ecg-add-patient">
										<i class="fa fa-plus"></i>
										<span class="text">X-Ray Report</span>
									</router-link>
								</li>
								<li>
                                    <router-link to="/center/ecg-report">
										<i class="fa fa-briefcase"></i>
										<span class="text">ECG Report</span>
									</router-link>
								</li>
							</ul>
						</li>
                        <li :class="{open: openDropdown == 'accounts'}">
							<a @click="openDropdown = openDropdown == 'accounts' ? '' : 'accounts'">
								<i class="pull-right fa fa-chevron-left"></i>
								<i class="fa fa-briefcase"></i>
								<span>Accounts</span>
							</a>
							<ul>
								<li>
                                    <router-link to="/center/ecg-report">
										<i class="fa fa-briefcase"></i>
										<span class="text">Received</span>
									</router-link>
								</li>
								<li>
									<router-link to="/center/ecg-add-patient">
										<i class="fa fa-plus"></i>
										<span class="text">Payment</span>
									</router-link>
								</li>
							</ul>
						</li>
						<li>
							<router-link to="/center/ecg-add-patient">
								<i class="fa fa-plus"></i>
								<span class="text">Agent</span>
							</router-link>
						</li>
                        <li :class="{open: openDropdown == 'mis'}">
							<a @click="openDropdown = openDropdown == 'mis' ? '' : 'mis'">
								<i class="pull-right fa fa-chevron-left"></i>
								<i class="fa fa-briefcase"></i>
								<span>M.I.S</span>
							</a>
							<ul>
								<li>
									<router-link to="/center/ecg-add-patient">
										<i class="fa fa-plus"></i>
										<span class="text">Payment</span>
									</router-link>
								</li>
								<li>
                                    <router-link to="/center/ecg-report">
										<i class="fa fa-briefcase"></i>
										<span class="text">Received</span>
									</router-link>
								</li>
								<li>
									<router-link to="/center/ecg-doctor">
										<i class="fa fa-briefcase"></i>
										<span class="text">Center</span>
									</router-link>
								</li>
								<li>
									<router-link to="/center/ecg-doctor">
										<i class="fa fa-briefcase"></i>
										<span class="text">Doctor</span>
									</router-link>
								</li>
							</ul>
						</li> -->
					</ul>
				</div>
			</div>
		</div>
		<div class="top-container">
			<header>
				<div class="rnav">
					<div @click="logOut" title="Logout">
						<i class="fa fa-lock"></i>
					</div>
					<div title="Speed check">
						<a href="https://fast.com" target="_balnk">
							<i class="fa fa-signal"></i>
						</a>
					</div>
					<div>
						<a target="_balnk" title="Contact coordinator" href="https://api.whatsapp.com/send?phone=8902035547">
							<i class="fa fa-whatsapp"></i>
						</a>
					</div>
					<div>
						<b>{{ doctorName }}</b>
					</div>
				</div>
				<div class="tnav">
					<ul class="clearfix">
						<li>
							<router-link to="/radiologist/dashboard">Dashboard</router-link>
						</li>

						
					</ul>
				</div>

				<div style="margin: auto; width: 225px; padding-top: 9px;">
					<router-link to="/radiologist/report/0" style="color: rgb(27, 212, 27); font-size: 24px; text-decoration: none;">
						<i class="fa fa-play"></i> Start Reporting
					</router-link>
				</div>

			</header>
			<div class="content">
				<router-view></router-view>
			</div>
		</div>

		<teleport v-if="false" to='body'>
			<div v-if="limitReached" style="width: 100%; height: 100%; position: fixed; z-index: 100; display: flex; align-items: center; justify-content: center; font-size: 20; background: #fff;">
				You have reached limit of maximum open 
			</div>
		</teleport>
	</div>
</template>
<script>

export default {
	data() {
		return {
			openDropdown: '',
			activeLink: '',
			doctorName: '',
			limitReached: false,
		}
	},
	beforeRouteEnter(req, res, next) {
		if (!window.localStorage.radiologist_access_token) return next("/radiologist-login");
		next();
	},
	mounted() {
		window.soc = io(apiOrigin, { transports: ["websocket"] });
		let at = JSON.parse(localStorage.radiologist_access_token);

		


		soc.on("connect", ()=>{
			console.log("Connecting... home.");
			soc.emit('login', {
				type: 'radiologist',
				id: at.id,
				browserId: localStorage.browserId
			});
		})


		soc.on('limitReached', d=>{
			this.limitReached = true;
		});

	},
	unmounted(){
		soc.close();
	},
	methods: {
		enter(){
			let u = JSON.parse(window.localStorage.radiologist_access_token);
			this.doctorName = u.name;
		},
		logOut() {
			delete window.localStorage.radiologist_access_token;
			this.$router.push("/radiologist-login");
		}
	},
}
</script>
<style scoped>
.top-container {
	min-height: 100vh;
	/* background: rgb(235 237 239); */
	background: rgb(247 247 247);
	padding-left: 56px;
}

header {
	background: #fff;
	box-shadow: 0px 4px 8px #ccc;
	border-bottom: 1px solid #ccc;
	height: 56px;

}

.rnav {
	float: right;
	padding-right: 10px;
}

.rnav>div {
	color: #555;
	padding: 15px;
	cursor: pointer;
	font-size: 18px;
	position: relative;
	float: right;
}

.rnav>div:hover {
	color: #333;
}

.rnav>div a {
	color: #555;
}

.tnav {
	float: left;
}

.tnav ul,
.tnav li {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
}

.tnav ul {
	padding-left: 15px;
}

.tnav li {
	float: left;
	padding: 15px;
}

.tnav li a {
	text-decoration: none;
	padding: 5px;
	font-size: 14px;
	color: rgba(0, 0, 21, .5);
	cursor: pointer;
}

.tnav li a:hover,
.tnav li a.router-link-exact-active {
	color: rgba(0, 0, 21, .7);
}

/* width */
.sidebar ::-webkit-scrollbar {
	width: 8px;
	border-radius: 20px;
}

/* button */
.sidebar ::-webkit-scrollbar-button {
	background: #222;
	height: 0;
}

/* Handle */
.sidebar ::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 8px;
}

/* Handle on hover */
.sidebar ::-webkit-scrollbar-thumb:hover {
	background: #aaa;
}

/* Track */
.sidebar ::-webkit-scrollbar-track {
	background: rgba(0, 0, 0, 0);
}

/* The track NOT covered by the handle.*/
.sidebar ::-webkit-scrollbar-track-piece {
	background: rgba(0, 0, 0, 0);
}

/* Corner */
.sidebar ::-webkit-scrollbar-corner {
	background: rgba(0, 0, 0, 0);
}

/* Resizer */
.sidebar ::-webkit-resizer {
	background: rgba(0, 0, 0, 0);
}

.sidebar {
	width: 56px;
	height: 100vh;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 5;
	overflow: hidden;
	/* background: rgb(60 75 100); */
	background: rgb(236 237 240);
	transition: width .3s;
	font-size: .875rem;
	border-right: 1px solid #ddd;
	box-shadow: 1px 0 14px #ccc;
}

.sidebar:hover {
	width: 256px;
}

.snav-container {
	height: 100%;
	padding-bottom: 56px;
}

.sidebar .snav {
	overflow-y: hidden;
}

.sidebar:hover .snav {
	height: 100%;
	overflow-y: overlay;
}

.sidebar .snav ul,
.sidebar .snav li {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
}

.sidebar .snav li {
	overflow: hidden;
	font-size: .875rem;
	font-weight: 400;
}


.sidebar .snav li a {
	overflow: hidden;
	/* color: rgba(255,255,255,.8); */
	color: #555;
	height: 56px;
	min-width: 256px;
	padding: 18px;
	padding-right: 10px;
	transition: background .3s, color .3s;
	text-decoration: none;
	display: block;
	cursor: pointer;
}

.sidebar .snav li a:hover,
.sidebar .snav li a.router-link-exact-active {
	color: #fff;
	/* background: #321fdb; */
	background: rgb(239 151 26);
}

.sidebar .snav li a * {
	visibility: hidden;
}

.sidebar .snav li a .fa {
	font-size: 17px;
	margin-right: 15px;
	visibility: visible;
}

.sidebar .snav li a .fa-chevron-left {
	font-size: 12px;
	margin-top: 5px;
	transition: transform .3s;
}

.sidebar:hover .snav li a * {
	visibility: visible;
}

.sidebar .snav>ul>li>ul {
	max-height: 0;
	overflow: hidden;
	transition: max-height .3s;
}

.sidebar:hover .snav>ul>li.open>ul {
	max-height: 900px;
}

.sidebar:hover .snav>ul>li.open>a .fa-chevron-left {
	transform: rotate(-90deg);
}

.sidebar .snav>ul>li>ul a {
	padding-left: 30px;
	/* background: rgb(81 98 126); */
	background: rgb(206 206 206);
}


.sidebar .title {
	text-transform: uppercase;
	color: rgba(255, 255, 255, .6);
	color: #000;
	height: 0px;
	overflow: hidden;
	transition: .3s;
}

.sidebar:hover .title {
	height: auto;
	padding: .75rem 1rem;
	margin-top: 1rem;
	font-size: 80%;
	font-weight: 700;
}

.logo {
	/* background: rgb(236 237 240); */
	background: rgb(206 206 206);
	height: 56px;
	position: relative;
	padding: 8px;
}

.logo img {
	display: block;
	max-height: 100%;
	max-width: 100%;
	margin: auto;
}

.logo .small {
	display: block;
}

.logo .full {
	display: none;
}

.sidebar:hover .logo .full {
	display: block;
}

.sidebar:hover .logo .small {
	display: none;
}
</style>